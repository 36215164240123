import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import CustomGatsbyImage from "@components/utils/CustomGatsbyImage"
import { Link } from "gatsby"
import Heading from "@components/ui/Heading"

const OtherCaseStudiesStyled = styled.section`
  background-color: #f2f2f2;
  box-shadow: inset 0 0 3px 1px rgba(0, 0, 0, 0.15);
  padding: 35px 0;

  ${breakpointsMedia(breakpoints.tabletWide)} {
    padding: 80px 0;
  }

  .title {
    text-align: center;
    margin-bottom: 30px;
    margin-top: 0;
  }

  .case-study-modules {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      gap: 32px;
      grid-template-columns: 1fr 1fr;
    }
  }

  .case-study-module {
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    padding: 16px 15px 16px 15px;
    display: block;
    text-decoration: none;
    transition: all 0.15s;
    width: 100%;
    margin: auto;
    max-width: 500px;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      padding: 16px 44px 16px 20px;
      width: auto;
      max-width: 100%;
      margin: 0;
    }

    &:hover {
      background-color: darken(white, 3%);
    }

    .inner {
      display: flex;
      height: 100%;
      align-items: center;

      .logo {
        max-width: 150px;
        width: auto;

        ${breakpointsMedia(breakpoints.tabletWide)} {
          max-width: 210px;
        }
      }

      .content-wrap {
        font-size: 24px;
        line-height: 29px;

        .name {
          color: var(--color-secondary);
        }

        .services {
          color: var(--color-text);
        }
      }
    }
  }
`

export const OtherCaseStudies = ({ title, caseStudies }) => {
  return (
    <OtherCaseStudiesStyled>
      <div className="container">
        <Heading node="h2" className="title">
          {title}
        </Heading>
        <div className="case-study-modules">
          {caseStudies.map(
            ({ uri, title, acfCaseStudyFeatured, taxServices }, key) => {
              const services = taxServices?.nodes
                ?.map(service => service.name)
                .join(" | ")
              return (
                <Link className="case-study-module" to={uri} key={key}>
                  <div className="inner">
                    <div className="logo">
                      <CustomGatsbyImage
                        {...acfCaseStudyFeatured?.featuredImage?.localFile}
                      />
                    </div>
                    <div className="content-wrap">
                      <div
                        className="name"
                        dangerouslySetInnerHTML={{ __html: title }}
                      />
                      {services && (
                        <div
                          className="services"
                          dangerouslySetInnerHTML={{ __html: services }}
                        />
                      )}
                    </div>
                  </div>
                </Link>
              )
            }
          )}
        </div>
      </div>
    </OtherCaseStudiesStyled>
  )
}

OtherCaseStudies.propTypes = {
  title: PropTypes.string,
}

OtherCaseStudies.defaultProps = {
  title: `Other relevant case studies`,
}
