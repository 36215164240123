import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"

import Seo from "@components/Seo"
import { CaseStudyHero } from "@components/sections/heros/CaseStudyHero"
import { CaseStudyServices } from "@components/sections/CaseStudyServices"
import {
  getCaseStudyEdges,
  useAllCaseStudies,
} from "@components/utils/useAllCaseStudies"
import { OtherCaseStudies } from "@components/sections/case-study/OtherCaseStudies"
import { CaseStudyArrows } from "@components/sections/case-study/CaseStudyArrows"

export default function CaseStudyPage({ data }) {
  const page = data.allWpCaseStudy.nodes[0]
  const {
    acfCaseStudyFields: { serviceCaseStudies },
    taxServices,
  } = page

  const caseStudies = serviceCaseStudies?.map(
    ({ acfCaseStudyContent }) => acfCaseStudyContent
  )

  const otherCaseStudiesByService = taxServices.nodes.map(
    service => service.otherCaseStudies
  )

  const otherCaseStudies =
    otherCaseStudiesByService.length && otherCaseStudiesByService[0]

  const filteredOtherCaseStudies = otherCaseStudies?.nodes?.filter(
    caseStudy => caseStudy.databaseId !== page.databaseId
  )

  const { next, previous } = getCaseStudyEdges(
    useAllCaseStudies(),
    page.databaseId
  )

  return (
    <>
      <Seo {...page.seo} />
      <Helmet bodyAttributes={{ class: "full-hero" }} />

      <CaseStudyHero
        nextCaseStudy={next}
        previousCaseStudy={previous}
        pageTitle={page.title}
        uri={page.uri}
        {...page.acfCaseStudyHero?.heroData}
      />
      {serviceCaseStudies && caseStudies && (
        <CaseStudyServices serviceCaseStudies={caseStudies} />
      )}
      {filteredOtherCaseStudies && (
        <OtherCaseStudies caseStudies={filteredOtherCaseStudies} />
      )}

      <CaseStudyArrows nextCaseStudy={next} previousCaseStudy={previous} />
    </>
  )
}

export const query = graphql`
  fragment caseStudyData on WpCaseStudy {
    featuredImage {
      node {
        id
        srcSet
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    acfCaseStudyHero {
      heroData {
        blurb
        subtitle
        title
        heroImage {
          localFile {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(
                quality: 85
                transformOptions: { cropFocus: CENTER }
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
    acfCaseStudyFields {
      serviceCaseStudies {
        ... on WpServiceCaseStudy {
          id
          acfCaseStudyContent {
            additionalContent {
              ... on WpServiceCaseStudy_Acfcasestudycontent_AdditionalContent_Testimonial {
                fieldGroupName
                attestant
                testimonial
                subTitle
                logo {
                  localFile {
                    extension
                    publicURL
                    childImageSharp {
                      gatsbyImageData(
                        width: 210
                        transformOptions: { cropFocus: CENTER }
                        layout: CONSTRAINED
                      )
                    }
                  }
                }
              }
              ... on WpServiceCaseStudy_Acfcasestudycontent_AdditionalContent_BannerImageBg {
                fieldGroupName
                content
                image {
                  localFile {
                    extension
                    publicURL
                    childImageSharp {
                      gatsbyImageData(
                        quality: 85
                        transformOptions: { cropFocus: CENTER }
                        layout: FULL_WIDTH
                      )
                    }
                  }
                }
              }
              ... on WpServiceCaseStudy_Acfcasestudycontent_AdditionalContent_ImageWithContent {
                fieldGroupName
                content
                direction
                image {
                  localFile {
                    extension
                    publicURL
                    childImageSharp {
                      gatsbyImageData(
                        width: 500
                        quality: 85
                        transformOptions: { cropFocus: CENTER }
                        layout: CONSTRAINED
                      )
                    }
                  }
                }
              }
              ... on WpServiceCaseStudy_Acfcasestudycontent_AdditionalContent_FigureStats {
                fieldGroupName
                chartSetup
                content
                title
                figures {
                  figure
                  text
                }
                lineChartDataset {
                  label
                  dataset {
                    data
                  }
                  borderColor
                }
                lineChartLabels {
                  label
                }
                figureSetup
              }
            }
            introduction
            whatWeDid {
              ... on WpServiceCaseStudy_Acfcasestudycontent_WhatWeDid_ImageGrid {
                fieldGroupName
                layout
                images {
                  localFile {
                    extension
                    publicURL
                    childImageSharp {
                      gatsbyImageData(
                        width: 500
                        quality: 85
                        transformOptions: { cropFocus: CENTER }
                        layout: CONSTRAINED
                      )
                    }
                  }
                }
              }
              ... on WpServiceCaseStudy_Acfcasestudycontent_WhatWeDid_Stats {
                fieldGroupName
                stats {
                  title
                  figure
                }
              }
              ... on WpServiceCaseStudy_Acfcasestudycontent_WhatWeDid_FullContent {
                content
                fieldGroupName
              }
              ... on WpServiceCaseStudy_Acfcasestudycontent_WhatWeDid_HalfContent {
                fieldGroupName
                leftContent
                rightContent
              }
            }
            service {
              ... on WpService {
                id
                title
                uri
                serviceDetails {
                  niceTitle
                  serviceIcon {
                    localFile {
                      publicURL
                      extension
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    taxServices {
      nodes {
        otherCaseStudies: caseStudies {
          nodes {
            title
            uri
            databaseId
            taxServices {
              nodes {
                name
              }
            }
            acfCaseStudyFeatured {
              featuredImage {
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      width: 210
                      quality: 85
                      transformOptions: { cropFocus: CENTER }
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  query($slug: String!) {
    allWpCaseStudy(filter: { slug: { eq: $slug } }) {
      nodes {
        databaseId
        id
        uri
        title
        link
        seo {
          canonical
          cornerstone
          focuskw
          metaDesc
          metaKeywords
          metaRobotsNofollow
          metaRobotsNoindex
          opengraphAuthor
          opengraphDescription
          opengraphModifiedTime
          opengraphPublishedTime
          opengraphImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FIXED)
              }
            }
          }
          opengraphPublisher
          opengraphSiteName
          opengraphTitle
          opengraphType
          opengraphUrl
          title
          twitterDescription
          twitterTitle
          schema {
            raw
          }
        }
        ...caseStudyData
      }
    }
  }
`
