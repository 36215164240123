import React from "react"
import styled from "styled-components"
import { CaseStudyWhatWeDid } from "./CaseStudyWhatWeDid"
import { CaseStudyAdditionalContent } from "./CaseStudyAdditionalContent"

const CaseStudyContentStyled = styled.div`
  .case-study-introduction {
    padding: 60px 0;
    background-color: #f8f7f7;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.15);

    .post-content {
      width: 942px;
      max-width: 100%;
      margin: auto;
    }
  }
`

export const CaseStudyContent = ({
  introduction,
  additionalContent,
  service,
  whatWeDid,
}) => {
  return (
    <CaseStudyContentStyled>
      {introduction && (
        <section
          className="case-study-introduction corner-bg left right "
          data-aos="fade"
          data-aos-duration="1000"
        >
          <div className="container">
            <div
              className="post-content"
              dangerouslySetInnerHTML={{ __html: introduction }}
            />
          </div>
        </section>
      )}

      {whatWeDid && <CaseStudyWhatWeDid acfSections={whatWeDid} />}
      {additionalContent && (
        <CaseStudyAdditionalContent acfSections={additionalContent} />
      )}
    </CaseStudyContentStyled>
  )
}

CaseStudyContent.propTypes = {}

CaseStudyContent.defaultProps = {}
