import React from "react"
import styled from "styled-components"
import CustomGatsbyImage from "@components/utils/CustomGatsbyImage"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import Heading from "@components/ui/Heading"

const TestimonialSectionStyled = styled.section`
  padding: 35px 0;

  ${breakpointsMedia(breakpoints.tabletWide)} {
    padding: 70px 0;
  }

  text-align: center;

  .attestant {
    text-align: center;
  }

  .sub_title {
    color: var(--color-secondary);
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    font-weight: 500;
  }

  .testimonial {
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    max-width: 746px;
    width: 100%;
    margin: 26px auto auto;
  }

  .logo-wrap {
    margin-top: 28px;
    text-align: center;

    img {
      max-width: 132px;
      margin: auto;
    }
  }
`

export const TestimonialSection = ({
  subTitle,
  attestant,
  testimonial,
  logo,
}) => {
  return (
    <TestimonialSectionStyled data-aos="fade-up" data-aos-duration="1000">
      <div className="container">
        {attestant && (
          <Heading node="h2" className="attestant">
            <span dangerouslySetInnerHTML={{ __html: attestant }} />
          </Heading>
        )}

        {subTitle && (
          <div
            className="sub_title"
            dangerouslySetInnerHTML={{ __html: subTitle }}
          />
        )}

        {testimonial && (
          <div
            className="testimonial"
            dangerouslySetInnerHTML={{ __html: testimonial }}
          />
        )}

        {logo && (
          <div className="logo-wrap">
            <CustomGatsbyImage {...logo.localFile} />
          </div>
        )}
      </div>
    </TestimonialSectionStyled>
  )
}

TestimonialSection.propTypes = {}

TestimonialSection.defaultProps = {}
