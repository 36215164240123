import React from "react"
import styled from "styled-components"
import CustomGatsbyImage from "../../../utils/CustomGatsbyImage"
import {
  breakpoints,
  breakpointsMedia,
} from "@styles/variables/Breakpoint"

const AddContentBannerImageStyled = styled.div`
  position: relative;
  padding: 36px 0;

  ${breakpointsMedia(breakpoints.tabletWide)} {
    padding: 77px 0;
  }

  .container {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      flex-direction: row;
    }
  }

  .content-wrap {
    color: white;
    font-size: 17px;
    line-height: 29px;
    flex: 0 0 50%;
    margin-top: 35px;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      margin-top: 0;
      margin-right: 35px;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      color: white;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .image-wrap {
    position: relative;
    flex: 1;
    width: 100%;

    .triangle-bl,
    .triangle-tr {
      z-index: 2;
    }

    .gatsby-image-wrapper {
      max-width: 100%;
    }

    > div {
      display: table;
      margin: auto;
      position: relative;
      width: 100%;
    }
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.65);
    }

    img,
    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
`

export const AddContentBannerImage = ({ content, image }) => {
  return (
    <AddContentBannerImageStyled
      className="additional-content banner-image-section"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div className="bg">
        <CustomGatsbyImage {...image.localFile} />
      </div>
      <div className="container">
        <div
          className="content-wrap"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <div className="image-wrap">
          <div>
            <div className="triangle-tr" />
            <div className="triangle-bl" />
            <CustomGatsbyImage {...image.localFile} />
          </div>
        </div>
      </div>
    </AddContentBannerImageStyled>
  )
}

AddContentBannerImage.propTypes = {}

AddContentBannerImage.defaultProps = {}
