import React, { useState } from "react"
import PropTypes from "prop-types"
import { CaseStudyServiceNav } from "./case-study/CaseStudyServiceNav"
import { CaseStudyContent } from "./case-study/CaseStudyContent"

export const CaseStudyServices = ({ serviceCaseStudies }) => {
  const services = serviceCaseStudies.map(({ service }) => service)
  const [selectedService, setService] = useState(services[0]?.id)

  if(!services || !services[0]?.id){
    return <></>
  }

  const service = serviceCaseStudies
    .filter(serviceData => {
      return serviceData.service?.id === selectedService
    })
    .shift()

  return (
    <>
      <CaseStudyServiceNav
        services={services}
        setService={setService}
        selectedService={selectedService}
      />
      {service && <CaseStudyContent {...service} />}
    </>
  )
}

CaseStudyServices.propTypes = {
  serviceCaseStudies: PropTypes.array.isRequired,
}
