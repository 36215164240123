import React, { useEffect } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import CustomGatsbyImage from "@components/utils/CustomGatsbyImage"
import { useHover } from "@components/utils/useHover"

/* eslint-disable react-hooks/exhaustive-deps */
export const CaseStudyServiceNavItem = ({
  id,
  title,
  icon,
  setService,
  isSelected,
  setHoveringRef,
}) => {
  const [navItemRef, isHovering] = useHover()

  const selectService = () => {
    setService({
      id,
      navItemRef,
    })
  }

  //select service on mount. This is to select default services
  useEffect(() => {
    if (isSelected) {
      selectService()
    }
  }, [])

  return (
    <div
      ref={navItemRef}
      className={classnames("service", {
        active: isSelected,
        hovering: isHovering,
      })}
      role="button"
      tabIndex={0}
      onClick={selectService}
      onKeyDown={selectService}
      onMouseEnter={() => setHoveringRef(navItemRef)}
      onMouseLeave={() => setHoveringRef(null)}
    >
      {icon && (
        <i className="icon">
          <CustomGatsbyImage {...icon.localFile} importSVG={true} />
        </i>
      )}
      <div
        className="service-name"
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
    </div>
  )
}

CaseStudyServiceNavItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.object,
  isSelected: PropTypes.bool,
  setService: PropTypes.func.isRequired,
  setHoveringRef: PropTypes.func.isRequired,
}

CaseStudyServiceNavItem.defaultProps = {
  isSelected: false,
}
