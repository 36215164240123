import React from "react"
import styled from "styled-components"
import * as PropTypes from "prop-types"
import { FontFamily } from "@styles/variables/FontFamily"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import Button from "../../../ui/buttons/Button"
import Heading from "@components/ui/Heading"

const ResultsAndStatsStyled = styled.section`
  padding-bottom: 0px;
  overflow: hidden;
  position: relative;

  margin-top: 36px;

  ${breakpointsMedia(breakpoints.tabletWide)} {
    margin-top: 89px;
  }

  .container > h2 {
    margin-top: 0;
  }

  .stats-row {
    display: flex;
    flex-direction: column;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      flex-direction: row;
      justify-content: space-between;
    }

    .stat-box {
      ${breakpointsMedia(breakpoints.tabletWide)} {
        max-width: 762px;
      }
    }

    .description {
      ${breakpointsMedia(breakpoints.tabletWide)} {
        flex: 0 0 313px;
      }
    }
  }

  .post-content {
    color: white;
    margin-bottom: 20px;
  }

  .results-and-stats {
    position: relative;
    margin: 36px 0;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      margin: 75px 0;
    }

    .stats .stat-box {
      margin-bottom: 15px;
      padding: 0;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      background-color: white;

      .stat-box-inner {
        display: flex;
        padding: 17px 24px;
        background-color: var(--color-bg);

        .img-wrapper {
          align-self: center;
        }

        .content-wrapper {
          display: flex;
          align-items: center;
          flex-direction: column;
          text-align: center;
          width: 100%;

          ${breakpointsMedia(breakpoints.tabletWide)} {
            flex-direction: row;
            text-align: left;
          }

          .stat-title {
            margin-bottom: 5px;
            margin-top: 0;

            ${breakpointsMedia(breakpoints.tabletWide)} {
              margin-bottom: 0;
              margin-right: 25px;
            }
          }

          .stat-info {
            font-family: ${FontFamily.BodyFont};
            font-size: 20px;
            font-weight: 300;
            line-height: 24px;
            color: var(--color-text);

            p:first-child {
              margin-top: 0;
            }

            p:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .description {
      padding: 20px 0;
      text-align: center;

      ${breakpointsMedia(breakpoints.tabletWide)} {
        padding: 0;
        text-align: left;
      }

      .title,
      .supporting-text {
        color: var(--color-text-light);
      }

      .supporting-text + .cta-wrap {
        margin-top: 29px;
      }

      .cta-wrap .btn {
        display: table;
      }

      .title {
        color: var(--color-text);
        font-family: ${FontFamily.BodyFont};
        font-size: 24px;
        letter-spacing: 0;
        margin-bottom: 5px;
      }
    }
  }
`

export const ResultsAndStats = ({ sectionTitle, title, content, figures }) => {
  return (
    <ResultsAndStatsStyled className="additional-content results-and-stats-wrap skew-bg">
      <div className="row">
        <section className="results-and-stats vertical">
          <div className="container">
            <Heading
              node="h2"
              color="white"
              className="aos-animate"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              {sectionTitle}
            </Heading>

            <div className="stats-row">
              <div className="stats">
                {figures &&
                  figures.map(({ text, figure }, key) => {
                    return (
                      <div className="stat-box" key={key}>
                        <div className="stat-box-inner">
                          <div className="content-wrapper purple">
                            <Heading
                              node="h3"
                              variant="h2"
                              className="stat-title"
                              color="pink"
                            >
                              {figure}
                            </Heading>
                            <p
                              className="stat-info"
                              dangerouslySetInnerHTML={{ __html: text }}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  })}
              </div>

              <div className="description">
                {title && (
                  <Heading node="h3" color="white">
                    <span dangerouslySetInnerHTML={{ __html: title }} />
                  </Heading>
                )}
                <div
                  className="supporting-text"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
                <div className="cta-wrap">
                  <Button color="white" to="/contact/">
                    Get in touch
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </ResultsAndStatsStyled>
  )
}

ResultsAndStats.propTypes = {
  title: PropTypes.string,
  sectionTitle: PropTypes.string,
  content: PropTypes.string,
  figures: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      figure: PropTypes.string,
    })
  ).isRequired,
}

ResultsAndStats.defaultProps = {
  sectionTitle: `Results & Stats`,
}
