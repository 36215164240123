import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { FontFamily } from "@styles/variables/FontFamily"

const WhatWeDidStatsStyled = styled.div`
  padding: 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #f8f7f7;
  margin-bottom: 45px;

  .stat-inner {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
  }

  .stat-item {
    text-align: center;
    font-family: ${FontFamily.Heading};
    flex: 1;
    min-width: 150px;
    padding: 19px;
    margin: 0 -1px;

    & + .stat-item {
      border-left: 1px solid rgba(76, 77, 78, 0.13);
    }

    .title {
      color: var(--color-text);
      font-weight: 500;
      font-size: 20px;
      line-height: 33px;
    }

    .figure {
      color: var(--color-secondary);
      font-weight: 500;
      font-size: 36px;
      line-height: 56px;
    }
  }
`

export const WhatWeDidStats = ({ stats }) => {
  return (
    <WhatWeDidStatsStyled
      className="stats-banner "
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div className="stat-inner">
        {stats.map(({ title, figure }, key) => {
          return (
            <div
              key={key}
              className="stat-item "
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div
                className="title"
                dangerouslySetInnerHTML={{ __html: title }}
              />
              <div
                className="figure"
                dangerouslySetInnerHTML={{ __html: figure }}
              />
            </div>
          )
        })}
      </div>
    </WhatWeDidStatsStyled>
  )
}

WhatWeDidStats.propTypes = {
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      figure: PropTypes.string,
    })
  ).isRequired,
}

WhatWeDidStats.defaultProps = {}
