import React from "react"
import styled from "styled-components"
import {
  breakpoints,
  breakpointsMedia,
} from "@styles/variables/Breakpoint"
import CustomGatsbyImage from "../../../utils/CustomGatsbyImage"

const WhatWeDidImageGridStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -23px;
  margin-bottom: 44px;

  @include mq($screen-sm) {
    margin-bottom: 60px;
  }

  &.half {
    .image-grid-item {
      flex: 100%;

      ${breakpointsMedia(breakpoints.tablet)} {
        flex: 0 0 50%;
      }
    }
  }

  &.one-third {
    .image-grid-item {
      flex: 100%;

      ${breakpointsMedia(breakpoints.phone)} {
        flex: 0 0 50%;
      }

      ${breakpointsMedia(breakpoints.tablet)} {
        flex: 0 0 33.33%;
      }
    }
  }

  &.full {
    .image-grid-item {
      flex: 100%;
    }
  }

  .image-grid-item {
    padding: 12px;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      padding: 23px;
    }
  }

  img {
    width: 100%;
    min-width: 200px;
  }
`

export const WhatWeDidImageGrid = ({ layout, images }) => {
  return (
    <WhatWeDidImageGridStyled className={layout}>
      {images.map((image, key) => {
        return (
          <div
            key={key}
            className="image-grid-item"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <CustomGatsbyImage {...image.localFile} />
          </div>
        )
      })}
    </WhatWeDidImageGridStyled>
  )
}

WhatWeDidImageGrid.propTypes = {}

WhatWeDidImageGrid.defaultProps = {}
