import React from "react"
import styled from "styled-components"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import { WhatWeDidStats } from "./what-we-did/WhatWeDidStats"
import { WhatWeDidImageGrid } from "./what-we-did/WhatWeDidImageGrid"
import { WhatWeDidFullContent } from "./what-we-did/WhatWeDidFullContent"
import { WhatWeDidSplitContent } from "./what-we-did/WhatWeDidSplitContent"
import Heading from "@components/ui/Heading"

const WhatWeDidImageGridStyled = styled.div`
  padding: 36px 0;

  ${breakpointsMedia(breakpoints.tabletWide)} {
    padding: 66px 0;
  }

  .what-we-do-title {
    margin-top: 0;
  }
`

export const CaseStudyWhatWeDid = ({ acfSections }) => {
  const sections = acfSections
    .filter(acfSection => acfSection)
    .map((acfLayout, key) => {
      let section = <></>

      switch (acfLayout.fieldGroupName) {
        case "serviceCaseStudy_Acfcasestudycontent_WhatWeDid_ImageGrid":
          if (acfLayout.images) {
            section = <WhatWeDidImageGrid key={key} {...acfLayout} />
          }
          break
        case "serviceCaseStudy_Acfcasestudycontent_WhatWeDid_Stats":
          if (acfLayout.stats) {
            section = <WhatWeDidStats key={key} stats={acfLayout.stats} />
          }
          break
        case "serviceCaseStudy_Acfcasestudycontent_WhatWeDid_HalfContent":
          section = <WhatWeDidSplitContent key={key} {...acfLayout} />
          break
        case "serviceCaseStudy_Acfcasestudycontent_WhatWeDid_FullContent":
          if (acfLayout.content) {
            section = (
              <WhatWeDidFullContent key={key} content={acfLayout.content} />
            )
          }
          break
        default:
          break
      }

      return section
    })

  return (
    <WhatWeDidImageGridStyled>
      <div className="container">
        <Heading
          node="h2"
          className="what-we-do-title "
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          What we did
        </Heading>
        <div className="what-we-did-modules">{sections}</div>
      </div>
    </WhatWeDidImageGridStyled>
  )
}

CaseStudyWhatWeDid.propTypes = {}
