import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import {
  breakpoints,
  breakpointsMedia,
} from "@styles/variables/Breakpoint"
import { FontFamily } from "@styles/variables/FontFamily"
import classnames from "classnames"
import { CaseStudyServiceNavItem } from "./CaseStudyServiceNavItem"

const CaseStudyServiceNavStyled = styled.div`
  background-color: var(--color-bg);
  width: 100%;
  z-index: 15;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.15);
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 73px;

  ${breakpointsMedia(breakpoints.tabletWide)} {
    flex-direction: row;
  }

  .indicator {
    border-radius: 5px;
    background-color: var(--color-primary);
    position: absolute;
    transition: all 0.25s;
    width: 100px;
    top: -12px;
    bottom: -12px;
    left: 0;
    opacity: 0;
    visibility: hidden;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      top: -12px !important;
      height: auto !important;
    }

    &.active {
      opacity: 1;
      visibility: visible;
    }

    &.clicked {
    }
  }

  .services-title {
    color: var(--color-text-light);
    font-family: ${FontFamily.Heading};
    font-weight: 500;
    font-size: 23px;
    line-height: 36px;
    position: absolute;
    bottom: 100%;
    transform: translateY(-26px);
  }

  .service {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    margin: 0 20px;
    outline: none;

    .icon {
      height: 44px;
      width: 45px;
      display: block;
      position: relative;
      border-radius: 100%;
      background: white;

      svg {
        max-width: 100%;
        max-height: 100%;

        path {
          transition: all 0.25s;
        }
      }
    }

    .service-name {
      color: var(--color-text);
      font-family: ${FontFamily.BodyFont};
      font-weight: 500;
      font-size: 17px;
      line-height: 20px;
      position: relative;
      transition: all 0.25s;
    }

    .icon + .service-name {
      margin-left: 18px;
    }
  }

  &:not(.hovering) .service.active,
  .service.hovering {
    .service-name {
      color: white;
    }

    .icon svg {
      path {
        fill: white;
      }
    }
  }
`

function setIndicator(indicatorEl, el) {
  indicatorEl.classList.add("active")
  indicatorEl.style.left = el.offsetLeft + "px"
  indicatorEl.style.top = el.offsetTop + "px"
  indicatorEl.style.width = el.clientWidth + "px"
  indicatorEl.style.height = el.clientHeight + "px"
}

//TODO: needs a refactor
export const CaseStudyServiceNav = ({
  services,
  selectedService,
  setService,
}) => {
  const [selectedServiceRef, setSelectedServiceRef] = useState(null)
  const [hoveringRef, setHoveringRef] = useState(null)
  const indicatorRef = useRef()

  const updateIndicator = () => {
    if (hoveringRef?.current) {
      //is hovering a service
      setIndicator(indicatorRef.current, hoveringRef.current)
    } else if (selectedServiceRef?.current) {
      //set selected service
      setIndicator(indicatorRef.current, selectedServiceRef.current)
    }
  }

  //update indicator position
  useEffect(updateIndicator, [hoveringRef, selectedService])
  updateIndicator()

  return (
    <CaseStudyServiceNavStyled
      className={classnames("case-study-services-nav", {
        hovering: hoveringRef,
      })}
    >
      <div className="services-title">Services provided:</div>
      <div className="indicator" ref={indicatorRef} />

      {services.map(({ title, serviceDetails, id }, key) => {
        const isSelected = id === selectedService

        return (
          <CaseStudyServiceNavItem
            key={key}
            title={serviceDetails?.niceTitle || title}
            icon={serviceDetails?.serviceIcon}
            id={id}
            isSelected={isSelected}
            setService={({ id, navItemRef }) => {
              setService(id)
              setSelectedServiceRef(navItemRef)
            }}
            setHoveringRef={setHoveringRef}
          />
        )
      })}
    </CaseStudyServiceNavStyled>
  )
}

CaseStudyServiceNav.propTypes = {
  services: PropTypes.array.isRequired,
  selectedService: PropTypes.string.isRequired,
  setService: PropTypes.func.isRequired,
}
