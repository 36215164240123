import React from "react"
import styled from "styled-components"
import {
  breakpoints,
  breakpointsMedia,
} from "@styles/variables/Breakpoint"
import CustomGatsbyImage from "../../../utils/CustomGatsbyImage"

const ImageWithContentStyled = styled.div`
  position: relative;
  margin: 36px 0;

  ${breakpointsMedia(breakpoints.tabletWide)} {
    margin: 70px 0;
  }

  & + & {
    ${breakpointsMedia(breakpoints.tabletWide)} {
      margin-top: -37px;
    }
  }

  .container {
    display: flex;
    align-items: center;
    flex-direction: column;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      flex-direction: row;
    }
  }

  ${breakpointsMedia(breakpoints.tabletWide)} {
    &.right {
      .container {
        .content-wrap {
          order: 1;
          margin-right: 53px;
        }

        .image-wrap {
          order: 2;
        }
      }
    }

    &.left {
      .container {
        .content-wrap {
          order: 2;
        }

        .image-wrap {
          order: 1;
          margin-right: 53px;
        }
      }
    }
  }

  .content-wrap {
    order: 1;
    font-size: 17px;
    line-height: 29px;
    flex: 1;
    margin-bottom: 35px;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      margin-bottom: 0;
    }

    > h2,
    > h3,
    > h4,
    > h5 {
      &:first-child {
        margin-top: 0;
      }
    }
  }

  .image-wrap {
    position: relative;
    order: 2;
    flex: 1;
    max-width: 468px;
    width: 100%;

    .gatsby-image-wrapper {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.27);
    }
  }
`

export const ImageWithContent = ({ direction, content, image }) => {
  return (
    <ImageWithContentStyled className={direction}>
      <div className="container">
        <div
          className="content-wrap"
          data-aos="fade-up"
          data-aos-duration="1000"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        {image && (
          <div
            className="image-wrap"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <CustomGatsbyImage {...image.localFile} />
          </div>
        )}
      </div>
    </ImageWithContentStyled>
  )
}

ImageWithContent.propTypes = {}

ImageWithContent.defaultProps = {}
