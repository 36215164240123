import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const WhatWeDidSplitContentStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(390px, 1fr));
  gap: 53px;
`

export const WhatWeDidSplitContent = ({ leftContent, rightContent }) => {
  return (
    <WhatWeDidSplitContentStyled data-aos="fade-up" data-aos-duration="1000">
      {leftContent && (
        <div
          className="post-content content"
          data-aos="fade-up"
          data-aos-duration="1000"
          dangerouslySetInnerHTML={{ __html: leftContent }}
        />
      )}
      {rightContent && (
        <div
          className="post-content content"
          data-aos="fade-up"
          data-aos-duration="1000"
          dangerouslySetInnerHTML={{ __html: rightContent }}
        />
      )}
    </WhatWeDidSplitContentStyled>
  )
}

WhatWeDidSplitContent.propTypes = {
  leftContent: PropTypes.string,
  rightContent: PropTypes.string,
}
