import React from "react"
import styled from "styled-components"
import {
  breakpoints,
  breakpointsMedia,
} from "@styles/variables/Breakpoint"
import { AddContentBannerImage } from "./additional-content/AddContentBannerImage"
import { ImageWithContent } from "./additional-content/ImageWithContent"
import { ResultsAndStats } from "./additional-content/ResultsAndStats"
import { TestimonialSection } from "./TestimonialSection"
import Loadable from "@loadable/component"

const ResultsAndStatsChart = Loadable(() =>
  import("./additional-content/ResultsAndStatsChart")
)

const CaseStudyAdditionalContentStyled = styled.div`
  margin-top: 36px;

  ${breakpointsMedia(breakpoints.tabletWide)} {
    margin-top: 89px;
  }
`

export const CaseStudyAdditionalContent = ({ acfSections }) => {
  const sections = acfSections
    .filter(acfSection => acfSection)
    .map((acfLayout, key) => {
      let section = <></>

      switch (acfLayout.fieldGroupName) {
        case "serviceCaseStudy_Acfcasestudycontent_AdditionalContent_BannerImageBg":
          section = <AddContentBannerImage {...acfLayout} />
          break
        case "serviceCaseStudy_Acfcasestudycontent_AdditionalContent_Testimonial":
          if (acfLayout.testimonial) {
            section = <TestimonialSection {...acfLayout} />
          }
          break
        case "serviceCaseStudy_Acfcasestudycontent_AdditionalContent_ImageWithContent":
          section = <ImageWithContent {...acfLayout} />
          break
        case "serviceCaseStudy_Acfcasestudycontent_AdditionalContent_FigureStats":
          if (acfLayout.figureSetup === "figure") {
            section = <ResultsAndStats {...acfLayout} />
          } else if (acfLayout.figureSetup === "chart") {
            section = <ResultsAndStatsChart {...acfLayout} />
          }

          break
        default:
          break
      }

      return section
    })

  return (
    <CaseStudyAdditionalContentStyled>
      <div className="additional-sections">{sections}</div>
    </CaseStudyAdditionalContentStyled>
  )
}

CaseStudyAdditionalContent.propTypes = {}

CaseStudyAdditionalContent.defaultProps = {}
