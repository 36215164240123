import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const WhatWeDidFullContentStyled = styled.div``

export const WhatWeDidFullContent = ({ content }) => {
  return (
    <WhatWeDidFullContentStyled
      className="post-content"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </WhatWeDidFullContentStyled>
  )
}

WhatWeDidFullContent.propTypes = {
  content: PropTypes.string.isRequired,
}
